<template>
  <div class="">
    <div class="d-flex flex-row">
      <div>
        <v-autocomplete
          v-model="supplierReturn.branch_id"
          class="text-body-1 rounded-lg mr-5"
          clearable
          dense
          flat
          :items="branches"
          item-text="name"
          item-value="id"
          hide-details
          no-data-text="Không có dữ liệu"
          placeholder="Chọn chi nhánh"
          solo
        ></v-autocomplete>
      </div>
      <div class="d_flex align-center px-0">
        <v-text-field
          v-model="serialNumber"
          class="text-body-1 rounded-lg mr-3"
          clearable
          dense
          flat
          hide-details
          placeholder="Nhập serial"
          prepend-inner-icon="mdi-magnify"
          solo
          @keypress.enter="getSerialInfo()"
        >
        </v-text-field>
      </div>
    </div>
    <v-card class="card-wrap mt-5" flat>
      <v-data-table
        class="table-scroll tp-table__row-pointer datatable px-3 py-2"
        calculate-widths
        disable-pagination
        no-data-text="Không có dữ liệu"
        no-results-text="Không tìm thấy kết quả phù hợp"
        :headers="headers"
        hide-default-footer
        :loading="false"
        loading-text="Đang tải dữ liệu"
        :items="supplierReturn.options"
        item-key="SKU"
      >
        <template v-slot:[`item.SKU`]="{ item }">
          {{ indexOfOptionSKUs(item.SKU) + 1 }}
        </template>

        <template v-slot:[`item.product_name`]="{ item }">
          <div class="my-1">
            <div class="font-weight-medium">
              {{ item.product_name }}
            </div>
            <div
              class="text-subtitle-2 font-weight-light grey--text text--darken-2"
              v-html="item.name"
            >
              {{ item.name }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.serial_number`]="{ item }">
          <v-chip
            class="font-weight-medium cyan lighten-4 mr-1 mt-1 px-2"
            small
          >
            {{ item.serial_number }}
          </v-chip>
        </template>

        <template v-slot:[`item.created_price`]="{ item }">
          {{ item.created_price | formatCurrency }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :color="hover ? 'grey darken-1' : 'grey lighten-1'"
              icon
              small
              @click.stop="removeProductOption(item)"
            >
              <v-icon size="18px">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-hover>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "SKU"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "serial_number"
        },
        {
          text: "Giá nhập",
          align: "start",
          sortable: false,
          value: "created_price"
        },
        {
          text: "Phiếu nhập",
          align: "start",
          sortable: false,
          value: "hoadonnhap_code"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ],
      productSearchKey: null,
      serialNumber: null
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    serialInfo() {
      return this.$store.getters["SERIAL/serialInfo"];
    },
    serialStatusRequest() {
      return this.$store.getters["SERIAL/statusRequest"];
    },
    supplierReturn() {
      return this.$store.getters["SUPPLIER_RETURN/supplierReturn"];
    },
    optionSKUs() {
      return this.supplierReturn.options.map(item => item.SKU);
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  watch: {
    //
  },
  async created() {
    if (this.branches.length === 0) {
      await this.$store.dispatch("BRANCH/getAllBranches");
    }
  },
  methods: {
    async getSerialInfo() {
      await this.$store.dispatch(
        "SERIAL/getSerialBySerialNumber",
        this.serialNumber
      );
      // Set goods destruction
      if (
        this.serialStatusRequest.value === "success-getSerialBySerialNumber" &&
        !this.optionSKUs.includes(this.serialInfo.option_sku)
      ) {
        this.supplierReturn.supplier_id = this.serialInfo.supplier_id;
        this.supplierReturn.branch_id = this.serialInfo.branch_id;

        this.supplierReturn.options.unshift({
          SKU: this.serialInfo.option_sku,
          created_price: this.serialInfo.created_price,
          name: this.serialInfo.option_name,
          product_name: this.serialInfo.product_name,
          hoadonnhap_code: this.serialInfo.hdn_code,
          serial_number: this.serialInfo.serial_number,
          serial_id: this.serialInfo.id
        });
      }
    },

    indexOfOptionSKUs(value) {
      return this.optionSKUs.indexOf(value);
    },

    removeProductOption(item) {
      this.supplierReturn.options.splice(this.indexOfOptionSKUs(item.SKU), 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 48px - 60px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 48px - 60px - 24px);
  }
}
.table-scroll {
  max-height: calc(100vh - 64px - 53px - 48px - 60px - 16px - 24px);
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 48px - 60px - 16px - 24px);
  }
}
</style>
