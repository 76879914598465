<template>
  <v-card class="card-wrap px-5 py-4" flat>
    <div>
      <div class="mb-1">
        <span class="font-weight-bold">Nhân viên: </span>
        <span>{{ user.name }}</span>
      </div>
      <div class="mb-1">
        <span class="font-weight-bold">Ngày tạo: </span>
        <span>{{ getToday() }}</span>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div>
      <div class="">
        <div class="font-weight-bold mb-2">Nhà cung cấp</div>
        <div class="d_flex align-center px-0">
          <v-autocomplete
            v-model="supplierReturn.supplier_id"
            class="text-body-1 mr-3"
            clearable
            dense
            flat
            hide-details
            :items="suppliers"
            item-text="code"
            item-value="id"
            :menu-props="{ maxWidth: '332px' }"
            no-data-text="Không có dữ liệu"
            placeholder="Tìm nhà cung cấp"
            prepend-inner-icon="mdi-magnify"
            solo
            outlined
          ></v-autocomplete>
        </div>
      </div>
    </div>

    <v-divider class="mt-6 mb-4"></v-divider>

    <div class="d-flex flex-row align-center justify-space-between">
      <div class="font-weight-bold">Nhà cung cấp cần trả</div>
      <div class="text-h6">
        {{ supplierReturnTotal | formatCurrency }}
      </div>
    </div>

    <div class="d-flex flex-row align-center justify-space-between mt-3">
      <div class="font-weight-bold">Tính vào công nợ</div>
      <div class="text-h6">
        {{ supplierReturnTotal | formatCurrency }}
      </div>
    </div>

    <div class="mt-4">
      <div class="font-weight-bold mb-2">Ghi chú</div>
      <v-textarea
        v-model="supplierReturn.note"
        class="text-body-1"
        background-color="white"
        dense
        filled
        outlined
        placeholder="Nhập ghi chú"
        single-line
        required
      ></v-textarea>
    </div>

    <v-card-actions class="justify-space-between pa-0">
      <v-btn
        class="rounded-lg"
        color="grey lighten-3"
        depressed
        @click="cancelCreatingSupplierReturn"
        >Hủy</v-btn
      >
      <v-btn
        class="rounded-lg"
        color="primary"
        depressed
        :loading="
          supplierReturnStatusRequest.value === 'loading-createSupplierReturn'
        "
        @click="
          supplierReturnStatusRequest.value === 'loading-createSupplierReturn'
            ? null
            : createSupplierReturn()
        "
        >Trả hàng NCC</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    supplierReturn() {
      return this.$store.getters["SUPPLIER_RETURN/supplierReturn"];
    },
    supplierReturnStatusRequest() {
      return this.$store.getters["SUPPLIER_RETURN/statusRequest"];
    },
    supplierReturnTotal() {
      let total = 0;

      this.supplierReturn.options.forEach(item => {
        total += item.created_price;
      });

      return total;
    },
    suppliers() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  async created() {
    if (this.suppliers) {
      await this.$store.dispatch("SUPPLIER/getAllSuppliers", "?sup_cate_ids=1");
    }
    if (this.employees && this.employees.length === 0)
      await this.$store.dispatch("EMPLOYEE/getAllEmployees");
  },
  methods: {
    cancelCreatingSupplierReturn() {
      this.$router.push({
        name: "transaction_supplier-return",
        query: {
          limit: 50,
          page: 1
        }
      });
    },

    async createSupplierReturn() {
      const obj = { ...this.supplierReturn };

      obj.serials = obj.options.map(item => item.serial_id);
      obj.value = this.supplierReturnTotal;
      // Request
      await this.$store.dispatch("SUPPLIER_RETURN/createSupplierReturn", obj);
      // Alert
      if (
        this.supplierReturnStatusRequest.value ===
        "success-createSupplierReturn"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Trả hàng NCC thành công"
          }
        });
        // Go to listing page
        this.$router.push({
          name: "transaction_supplier-return",
          query: {
            limit: 50,
            page: 1
          }
        });
      }
    },
    getToday() {
      const date = new Date(),
        hour = date
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = date
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        dd = date
          .getDate()
          .toString()
          .padStart(2, "0"),
        mm = (date.getMonth() + 1).toString().padStart(2, "0"),
        yyyy = date.getFullYear();

      return `${hour}:${mins} ${dd}/${mm}/${yyyy}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 48px - 24px);
  @media only screen and (max-width: 960px) {
    min-height: calc(100vh - 56px - 53px - 48px - 24px);
  }
}
</style>
