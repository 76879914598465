var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_c('v-autocomplete',{staticClass:"text-body-1 rounded-lg mr-5",attrs:{"clearable":"","dense":"","flat":"","items":_vm.branches,"item-text":"name","item-value":"id","hide-details":"","no-data-text":"Không có dữ liệu","placeholder":"Chọn chi nhánh","solo":""},model:{value:(_vm.supplierReturn.branch_id),callback:function ($$v) {_vm.$set(_vm.supplierReturn, "branch_id", $$v)},expression:"supplierReturn.branch_id"}})],1),_c('div',{staticClass:"d_flex align-center px-0"},[_c('v-text-field',{staticClass:"text-body-1 rounded-lg mr-3",attrs:{"clearable":"","dense":"","flat":"","hide-details":"","placeholder":"Nhập serial","prepend-inner-icon":"mdi-magnify","solo":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getSerialInfo()}},model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}})],1)]),_c('v-card',{staticClass:"card-wrap mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.supplierReturn.options,"item-key":"SKU"},scopedSlots:_vm._u([{key:"item.SKU",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.indexOfOptionSKUs(item.SKU) + 1)+" ")]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.product_name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-light grey--text text--darken-2",domProps:{"innerHTML":_vm._s(item.name)}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.serial_number",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium cyan lighten-4 mr-1 mt-1 px-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.serial_number)+" ")])]}},{key:"item.created_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.created_price))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'grey darken-1' : 'grey lighten-1',"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeProductOption(item)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }